<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcGideonCardRestockingVisits }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcTooltip"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->

      <data-table
        :fields="dataTableFields"
        :listType="list_type"
        :items="restockingVisits"
        :toggleMe="true"
        :addItems="addItems"
        :searchTerm="`visitsTerm`"
        :csvUrl="csvUrl"
        :pdfUrl="pdfUrl"
        :reportName="reportName"
        :includeAddButton="includeAddButton"
        @addVisitClick="handleAddVisitClick"
        :i18n="translations.components"
      ></data-table>
      <!-- / Data Tables -->

      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'restocking-visits',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        add_gideoncard_restocking_visit_button: '7fdd519a-35d1-4eca-b374-e96e8f05efbe',
      },
      list_type: 'restocking_visit',
      dataTableFields: [
        {
          key: 'activity_date',
          label: '',
          sortable: true,
        },
        { key: 'facility_name', label: '', sortable: true },
        { key: 'city', label: '', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Restocking Visit',
        action: 'addVisitClick',
      },
      includeAddButton: true,
      reportName: 'GideonCardRestockingVisits',
    }
  },
  methods: {
    ...mapActions({
      clearFacilityDetails: 'card/clearFacilityDetails',
      clearRestockingVisitDetails: 'card/clearRestockingVisitDetails',
      getRestockingVisits: 'card/getRestockingVisits',
      getRestockingVisitCSVUrl: 'card/getRestockingVisitCSVUrl',
      getRestockingVisitPDFUrl: 'card/getRestockingVisitPDFUrl',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getRestockingVisits(),
          this.getRestockingVisitCSVUrl(),
          this.getRestockingVisitPDFUrl(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addPresenter() {
      this.$router.push('/')
    },
    async handleAddVisitClick() {
      this.setSelectedFacilityKey(null)
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      this.$router.push('/programs/gc/events/card_restocking_visit_add')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddVisit
      this.addItems.display = this.iCanSee(this.secured_controls.add_gideoncard_restocking_visit_button)
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        {
          key: 'activity_date',
          label: this.translations.tcVisitDate,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
          sortable: true,
        },
        { key: 'facility_name', label: this.translations.tcFacilityName, sortable: true },
        { key: 'city', label: this.translations.tcCity, sortable: false },
      ]
    },
  },
  async created() {
    Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table', 'security-banner', 'camp-select'),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'card/restockingVisitCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      restockingVisits: 'card/restockingVisits',
      userOrgKey: 'user/userOrgKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
